/*= ========================================================================================
  File Name: moduleLeadState.js
  Description: Lead Module State
  ----------------------------------------------------------------------------------------
  Item Name: Spellty app
  Author: fjose
========================================================================================== */

export default {
  accountInfo: {
    name: '',
    nit: '',
    type: '',
    country: '',
    state: '',
    city: '',
  },

}
