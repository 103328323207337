/*= ========================================================================================
  File Name: moduleLeadState.js
  Description: Lead Module State
  ----------------------------------------------------------------------------------------
  Item Name: Spellty app
  Author: fjose
========================================================================================== */

export default {
  contactInfo: {
    id: '',
    firstname: '',
    lastname: '',
    identification_type_id: '1',
    identification: '',
    country: 'COL',
    state: '05',
    city: '05001',
    account_id: '',
    mobile_number: '',
    email: '',
    account: {
      city: '05001',
      country: 'COL',
      id_account: '',
      id_account_type: 'Cliente',
      identification: null,
      last_name: '',
      name: '',
      state: '05',
    },
  },
  accounts: [],
  contacts: [{
    text: 'Test',
  }],
  istSearchAccounts: true,
}
