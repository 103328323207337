/*=========================================================================================
  File Name: moduleReleaseMutations.js
  Description: Release Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Release mutations
  Author: fjose
==========================================================================================*/


export default {
  UPDATE_RELEASE_NOTES(state, releaseData) {
    state.releaseItems = releaseData
  }
}
