export default {
  UPDATE_AUTHENTICATED_USER(state, isLoggedIn) {
    /// state.isLoggedIn = isLoggedIn;
    // state.isLoggedIn = () => {
    if (localStorage.getItem('access_token') && localStorage.getItem('userInfo')) {
      state.isLoggedIn = true
      state.userInfo = localStorage.getItem('userInfo')
    } else {
      state.isLoggedIn = false
    }
  },
}