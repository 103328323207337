// client.js

const axios = require('axios')

let baseURL = 'http://localhost:8080/SpringBootTime2ChangeSpellty-APP'

const mode = process.env.NODE_ENV

if (mode === 'production') {
  baseURL = 'https://t2c.arqbs.com/SpringBootTime2ChangeSpellty-APP'
}



const axiosInstance = axios.create({
  baseURL,
})

axiosInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  (data) => {
    console.log(data);
    if(data.response.status == 401){
      dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
      payload.toast({
        component: ToastificationContent,
        position: "top-right",
        props: {
          title: "Sesion caducada",
          icon: "CoffeeIcon",
          variant: "warning",
          text: error.response.data.error,
        }
    })
  }
  } 
)

module.exports = { axiosInstance }
