export default {

    access_token: () =>{
      return '';
    },
    loggedIn: () => {
      let isAuthenticated = false;
      
      if(localStorage.getItem('userInfo')) isAuthenticated = true;
      
      return isAuthenticated;
    },
  }