/*=========================================================================================
  File Name: moduleLeadMutations.js
  Description: Lead Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Lead mutations
  Author: fjose
==========================================================================================*/


export default {

  UPDATE_ACCOUNT_INFO(state, payload) {
    state.accountInfo = payload
  },
}
