import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '../../router'
import { axiosInstance } from '../../axios.conf'

export default {
  login({
    commit, state, dispatch, getters, rootState,
  }, payload) {
    axiosInstance
      .post('/oauth/v1/auth', {
        usuario: payload.userDetails.email,
        password: payload.userDetails.password,
      })
      .then(response => {
        if (response.status === 200 && response.data.token) {
          const userData = response.data.agent
          response = response.data
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${response.token}`

          localStorage.setItem('access_token', response.token)
          localStorage.setItem('agentInfo', JSON.stringify(response.agent))

          commit('UPDATE_AUTHENTICATED_USER', true)

          // Setting the  layout list and the default

          // router.push(router.currentRoute.query.to || '/mainform');
          router.push(router.currentRoute.query.to || '/apps/chat').then(() => {
            payload.toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: `Bienvenid@ ${userData.name || userData.last_name}`,
                icon: 'user-check',
                variant: 'success',
                text: 'Has iniciado sesión con éxito',
              },
            })
          })
        } else {
          payload.toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.message,
              icon: 'user-x',
              variant: 'danger',
              text: response?.data?.errors[0],
            },
          })
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status == 401 || error.response.status == 403 || error.response.status == 402) {
            dispatch('logOutAttemptDuplicate', payload)
            payload.toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Sesion caducada',
                icon: 'user-x',
                variant: 'danger',
                text: error?.response?.data?.error,
              },
            })
          }
        } else {
          console.log(error)
          payload.toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Error de servidor',
              icon: 'user-x',
              variant: 'danger',
              text: error?.response?.data?.error,
            },
          })
        }
      })
  },
  logOutAttemptDuplicate({ commit, dispatch }, payload) {
    localStorage.removeItem('access_token')
    localStorage.removeItem('agentInfo')
    axiosInstance.defaults.headers.common.Authorization = null
    commit('UPDATE_AUTHENTICATED_USER', false)
    dispatch('timeToChage', false, { root: true })
    router.push('/login')
  },
  updateAuthenticatedUser({ commit }, payload) {
    commit('UPDATE_AUTHENTICATED_USER', payload)
  },
}
