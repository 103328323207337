/*=========================================================================================
  File Name: moduleChat.js
  Description: Chat Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: fjose
==========================================================================================*/


import state from './moduleContactState.js'
import mutations from './moduleContactMutations.js'
import actions from './moduleContactActions.js'
import getters from './moduleContactGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}