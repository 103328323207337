/*= ========================================================================================
  File Name: state.js
  Description: Vuex Store - state
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: fjose
========================================================================================== */


import { $themeConfig, $themeColors } from '../../themeConfig'

const state = {
  isSidebarActive: true,
  breakpoint: null,
  sidebarWidth: 'default',
  reduceButton: $themeConfig.layout.sidebarCollapsed,
  bodyOverlay: false,
  sidebarItemsMin: false,
  theme: $themeConfig.layout.theme || 'light',
  // navbarSearchAndPinList: navbarSearchAndPinList,
  AppActiveUser: {
    id: 0,
    name: 'John Doe',
    about: 'Dessert chocolate cake lemon drops jujubes. Biscuit cupcake ice cream bear claw brownie brownie marshmallow.',
    img: 'avatar-s-11.png',
    status: 'online',
  },

  themePrimaryColor: $themeColors.primary,

  // starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
  userRole: null,

  // Can be used to get current window with
  // Note: Above breakpoint state is for internal use of sidebar component
  windowWidth: null,

  // Controller the Components to show: Contact info or Lead info or Form to add data
  isContactInfo: null,
  isFormAddData: true,
  isEditForm: false,

  // Alert for display the latest version of info about contact or lead
  stackRequest: [],
  dateTimeDataLocalStorange: null,
  showAlertUpdateLocalStorange: false,

  // Control to lock asynchronous updates in view components
  mobileNumberSelected: null,

  timeToChangeOverlay: false,
  timeToChangeStatus: false,

  temp: {
    mobile: '',
    mobileOpt1: null,
    isGroup: false,
    messageUnsupp: '',
    titleUnsupp: '',
    mobileFormatInternational: '',
  },

}

export default state
