/*=========================================================================================
  File Name: moduleReleaseActions.js
  Description: Release Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Spellty app
  Author: fjose
==========================================================================================*/

import {axiosInstance} from '../../axios.conf';


export default {


  releaseNotifications({ commit, state, dispatch }) {

    axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded';
    axiosInstance.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('access_token');


    let isActiveNotifications = '1';
    let language = 'en';
    const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem("userInfo")): null;

    if (userInfo.language) {
      if(userInfo.language === "po"){
        language = "br";
      }else if(userInfo.language.startsWith("es")){
        language = "es";
      }else{
        language = "en"
      }
    }

    /* */
    axiosInstance.post('/api/v1/release-notifications', {
      active: isActiveNotifications,
      language: language

    }).then(response => {
     
      if (response.data.success) {

        commit('UPDATE_RELEASE_NOTES', response.data.data)
      }else{
        let data = {
          Contacts: null,
          Leads:null
        }

        commit('UPDATE_RELEASE_NOTES', data)
      }
    }).catch(error => {
      console.log("Error saving the Chat", error);
    });

  },



}
