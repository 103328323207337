/*=========================================================================================
  File Name: moduleDeals.js
  Description: Deal Module
  ----------------------------------------------------------------------------------------
  Author: fjose
==========================================================================================*/


import state from './moduleReleaseState.js'
import mutations from './moduleReleaseMutations.js'
import actions from './moduleReleaseActions.js'
import getters from './moduleReleaseGetters.js'

export default {
	namespaced: true,
    state: state,
    mutations: mutations,
    actions: actions,
    getters: getters
}
