/*= ========================================================================================
  File Name: moduleLeadState.js
  Description: Lead Module State
  ----------------------------------------------------------------------------------------
  Item Name: Spellty app
  Author: fjose
========================================================================================== */

export default {
  ticketInfo: {
    name: '',
    nit: '',
    type: '',
    country: '',
    state: '',
    city: '',
  },
  supportTypes: [],
  ticketTypes: [],
  tickets:[],
  accountsTicket: [],
  allyAccounts: [],
  DisAccounts: [],
  istSearchAccountsTicket: true,
  istSearchAllyAccounts: true,
  istSearchDisAccounts: true,
  ticketsPages: 0,
  moreTickets: true
}
