import {axiosInstance} from '../../axios.conf'
import router from '../../router'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {

  createAccount({ commit, state, dispatch }, payload) {
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`

    axiosInstance
      .post('/api/v1/account', {
        ...payload.accountDetails,
      })
      .then(response => {
        if (response.status === 200) {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Creación exitosa",
              icon: "CoffeeIcon",
              variant: "success",
              text: "Se ha creado la cuenta exitosamente",
            }
          })
        }
      }).catch(error => {
        // Close animation if passed as payload
        dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
        payload.toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Sesion caducada",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Token de acceso expirado",
          }
        })

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 402) {
            dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
            
            payload.toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Sesion caducada",
                icon: "CoffeeIcon",
                variant: "warning",
                text: error.response.data.error,
              }
            })
          }
        } else {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error de servidor",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.error,
            }
          })
        }
      })
  },

}
