/*= ========================================================================================
  File Name: moduleChat.js
  Description: Chat Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: fjose
========================================================================================== */

import state from './moduleAccountState.js'
import mutations from './moduleAccountMutations.js'
import actions from './moduleAccountActions.js'
import getters from './moduleAccountGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
