/*= ========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Author: fjose
========================================================================================== */


const getters = {

  getContactInfoFromLocalStorange: (state, getters) => payload => {
    let data = null
    const data2 = null

    data = localStorage.getItem(`whatsAppContactInfo_${payload.mobileOpt1}`)
    // data2 = localStorage.getItem('whatsAppContactInfo_'+payload.mobileOpt2)

    if (data) {
      data = JSON.parse(data)
      const today = new Date()
      const dateRecordSaved = new Date(data.dateTime)

      const diffTime = Math.abs(today - dateRecordSaved)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))

      if (diffDays > 1) {
        data = null
      }
    }

    return data
  },
  
  clearBackslashes: (state, getters) => formatNumbers => {
    formatNumbers.originalNumber = formatNumbers.originalNumber.replace(')', '\\)').replace('(', '\\(')
    formatNumbers.fullNumberOriginal = formatNumbers.fullNumberOriginal.replace(')', '\\)').replace('(', '\\(')
    formatNumbers.nationalPhoneNumber = formatNumbers.nationalPhoneNumber.replace(')', '\\)').replace('(', '\\(')
    formatNumbers.internatialPhoneNumber = formatNumbers.internatialPhoneNumber.replace(')', '\\)').replace('(', '\\(')
    formatNumbers.originalNumberWithoutCode = formatNumbers.originalNumberWithoutCode.replace(')', '\\)').replace('(', '\\(')
    formatNumbers.nationalPhoneNumberWithoutSpaces = formatNumbers.nationalPhoneNumberWithoutSpaces.replace(')', '\\)').replace('(', '\\(')
    formatNumbers.internatialPhoneNumberWithoutSpaces = formatNumbers.internatialPhoneNumberWithoutSpaces.replace(')', '\\)').replace('(', '\\(')
    formatNumbers.originalNumberWithPlus = formatNumbers.originalNumberWithPlus.replace(')', '\\)').replace('(', '\\(')
    formatNumbers.originalNumberWithoutCodeWithSpace = formatNumbers.originalNumberWithoutCodeWithSpace.replace(')', '\\)').replace('(', '\\(')

    /*
    var formatNumbersQuery =  "(Mobile:equals:"+formatNumbers.originalNumber+")or(Mobile:equals:"+formatNumbers.fullNumberOriginal+")or(Mobile:equals:"+formatNumbers.nationalPhoneNumber+")or"+
                              "(Mobile:equals:"+formatNumbers.internatialPhoneNumber+")or"+
                              "(Mobile:equals:"+formatNumbers.originalNumberWithoutCode+")or(Mobile:equals:"+formatNumbers.nationalPhoneNumberWithoutSpaces+")or"+
                              "(Mobile:equals:"+formatNumbers.internatialPhoneNumberWithoutSpaces+")or(Mobile:equals:"+formatNumbers.originalNumberWithPlus+")"
    */

    const formatNumbersQuery = {
      mobileOptions:
                    `${formatNumbers.originalNumber}&${formatNumbers.fullNumberOriginal}&${
                      formatNumbers.nationalPhoneNumber}&${formatNumbers.internatialPhoneNumber}&${
                      formatNumbers.originalNumberWithoutCode}&${formatNumbers.nationalPhoneNumberWithoutSpaces}&${
                      formatNumbers.internatialPhoneNumberWithoutSpaces}&${formatNumbers.originalNumberWithPlus}&${
                      formatNumbers.originalNumberWithPlus}&${formatNumbers.originalNumberWithoutCodeWithSpace}`,
    }

    return formatNumbersQuery
  },


}

export default getters
