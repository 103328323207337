import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'
import apps from './routes/apps'
import pages from './routes/pages'
import store from '../store/index'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'apps-chat' } },
    ...apps,
    ...pages,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, from, next) => {
  let isAuthenticated = store.state.auth.isLoggedIn

  if (localStorage.getItem('access_token') && localStorage.getItem('agentInfo')) {
    isAuthenticated = true
  } else {
    isAuthenticated = false
  }

  if (to.path === '/login' || isAuthenticated) {
    return next()
  }
  if (from.path !== '/login') {
    router.push({ path: '/login', query: { to: to.path } })
      .catch(error => { console.log('Error in router.beforeEach', router.beforeEach) })
  }
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
