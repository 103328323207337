/*=========================================================================================
  File Name: moduleReleaseState.js
  Description: Release Module State
  ----------------------------------------------------------------------------------------
  Item Name: Spellty app
  Author: fjose
==========================================================================================*/




export default {

  releaseItems: {},
 }
