/*=========================================================================================
  File Name: moduleLeadMutations.js
  Description: Lead Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Lead mutations
  Author: fjose
==========================================================================================*/


export default {

  UPDATE_CONTACT_INFO(state, payload) {
    state.contactInfo = payload
  },
  UPDATE_ACCOUNTS(state, payload) {
    state.accounts = payload
  },
  UPDATE_CONTACTS(state, payload) {
    state.contacts = payload
  },
  UPDATE_ITS_SEARCH_ACCOUNTS(state, payload) {
    state.istSearchAccounts = payload
  },
}
