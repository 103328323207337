/*=========================================================================================
  File Name: moduleLeadMutations.js
  Description: Lead Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Lead mutations
  Author: fjose
==========================================================================================*/


export default {

  UPDATE_TICKET_TYPES_INFO(state, payload) {
    state.ticketTypes = payload
  },
  UPDATE_SUPPORT_TYPES_INFO(state, payload) {
    state.supportTypes = payload
  },
  UPDATE_ACCOUNTS(state, payload) {
    state.accountsTicket = payload
  },
  UPDATE_ITS_SEARCH_ACCOUNTS(state, payload) {
    state.istSearchAccountsTicket = payload
  },
  UPDATE_TICKETS(state, payload){
    state.tickets = payload
  },
  UPDATE_ADD_TICKETS(state, payload){
    payload.forEach(element => state.tickets.push(element))
  },
  UPDATE_ALLY_ACCOUNTS(state, payload) {
    state.allyAccounts = payload
  },
  UPDATE_DIS_ACCOUNTS(state, payload) {
    state.DisAccounts = payload
  },
  UPDATE_ITS_AllY_SEARCH_ACCOUNTS(state, payload) {
    state.istSearchAllyAccounts = payload
  },
  UPDATE_ITS_DIS_SEARCH_ACCOUNTS(state, payload) {
    state.istSearchDisAccounts = payload
  },
  UPDATE_TICKETS_PAGES(state, payload){
    state.ticketsPages= payload
  },
  UPDATE_MORE_TICKETS(state, payload){
    state.moreTickets= payload
  }
}
