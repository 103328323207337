/*=========================================================================================
  File Name: getters.js
  Description: Vuex Store - getters
  ----------------------------------------------------------------------------------------
  Author: fjose
==========================================================================================*/

const getters = {

  

}

export default getters
