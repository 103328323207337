import {axiosInstance} from '../../axios.conf'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {

  createContact({ commit, state, dispatch }, payload) {
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`

    axiosInstance
      .post('/api/v1/contact', {
        ...payload.contactDetails,
      })
      .then(response => {
        if (response.status === 200) {
          const data = {
            id: response.data.data.contact_id,
            ...payload.contactDetails,
          }
          if (payload.contactDetails.account_id) {
            axiosInstance
              .post('/api/v1/contact/linkContactToAccount', {
                account_id: payload.contactDetails.account_id,
                contact_id: response.data.data.contact_id,
              })
              .then(linkResponse => {
                if (linkResponse.data.status === 'success') {
                  dispatch('whatsAppContactInfoFromServer', { mobileOpt1: payload.contactDetails.mobile_number }, { root: true })
                  payload.toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: "Creación exitosa",
                      icon: "CoffeeIcon",
                      variant: "success",
                      text: "Se ha creado el contacto exitosamente",
                    }
                  })
                } else {
                  payload.toast({
                    component: ToastificationContent,
                    position: "top-right",
                    props: {
                      title: "Vinculación fallida",
                      icon: "CoffeeIcon",
                      variant: "danger",
                      text: "Ha fallado la vinculación con la compañia",
                    }
                  })
                }
              }).catch(error => {
                console.log(error)
              })
          } else {
            dispatch('whatsAppContactInfoFromServer', { mobileOpt1: payload.contactDetails.mobile_number }, { root: true })
            payload.toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Creación exitosa",
                icon: "CoffeeIcon",
                variant: "success",
                text: "Se ha creado el contacto exitosamente",
              }
            })
          }
        } else {
          commit('SHOW_FORM_ADD_DATA', null, { root: true })
        }
      }).catch(error => {
        // Close animation if passed as payload
        dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
        payload.toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Sesion caducada",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Token de acceso expirado",
          }
        })

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 402) {
            dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
            
            payload.toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Sesion caducada",
                icon: "CoffeeIcon",
                variant: "warning",
                text: error.response.data.error,
              }
            })
          }
        } else {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error de servidor",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.error,
            }
          })
        }
      })
  },
  searchAccounts({ commit, state, dispatch }, payload) {
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`

    axiosInstance
      .post('/api/v1/searchAccount', {
        query: payload.query,
      })
      .then(response => {
        if (response.data.data.data.length > 0) {
          let dataFormat = response.data.data.data.map(account => {
            const accFormat = {
              city: account.city,
              country: account.country,
              value: account.id_account,
              id_account_type: account.id_account_type,
              id_identification_type: account.id_identification_type,
              identification: account.identification,
              last_name: account.last_name,
              text: account.name,
              state: account.state,
            }
            return accFormat
          })
          if (payload.isTicket) {
            dataFormat = dataFormat.filter(acc => acc.id_account_type !== 'Cliente')
            commit('ticket/UPDATE_ACCOUNTS', dataFormat, { root: true })
            commit('ticket/UPDATE_ITS_SEARCH_ACCOUNTS', false, { root: true })
          } else {
            commit('UPDATE_ACCOUNTS', dataFormat)
            commit('UPDATE_ITS_SEARCH_ACCOUNTS', false)
          }
        } else if (payload.isTicket) {
          commit('ticket/UPDATE_ACCOUNTS', [], { root: true })
          commit('ticket/UPDATE_ITS_SEARCH_ACCOUNTS', false, { root: true })
        } else {
          commit('UPDATE_ACCOUNTS', [])
          commit('UPDATE_ITS_SEARCH_ACCOUNTS', false)
        }
      }).catch(error => {
        // Close animation if passed as payload
        dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
        payload.toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Sesion caducada",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Token de acceso expirado",
          }
        })

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 402) {
            dispatch('auth/logOutAttemptDuplicate', payload , {root: true})
            
            payload.toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Sesion caducada",
                icon: "CoffeeIcon",
                variant: "warning",
                text: error.response.data.error,
              }
            })
          }
        } else {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error de servidor",
              icon: "CoffeeIcon",
              variant: "error",
              text: error.response.data.error,
            }
          })
        }
      })
  },
  searchAllyAccounts({ commit, state, dispatch }, payload) {
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`

    axiosInstance
      .post('/api/v1/searchAccount', {
        query: payload.query,
      })
      .then(response => {
        if (response.data.data.data.length > 0) {
          let dataFormat = response.data.data.data.map(account => {
            const accFormat = {
              city: account.city,
              country: account.country,
              value: account.id_account,
              id_account_type: account.id_account_type,
              id_identification_type: account.id_identification_type,
              identification: account.identification,
              last_name: account.last_name,
              text: account.name,
              state: account.state,
            }
            return accFormat
          })
            dataFormat = dataFormat.filter(acc => acc.id_account_type !== 'Cliente' && acc.id_account_type === 'Aliado')
            commit('ticket/UPDATE_ALLY_ACCOUNTS', dataFormat, { root: true })
            commit('ticket/UPDATE_ITS_AllY_SEARCH_ACCOUNTS', false, { root: true })
          
        } else  {
          commit('ticket/UPDATE_ALLY_ACCOUNTS', [], { root: true })
          commit('ticket/UPDATE_ITS_AllY_SEARCH_ACCOUNTS', false, { root: true })
        }
      }).catch(error => {
        // Close animation if passed as payload
        dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
        payload.toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Sesion caducada",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Token de acceso expirado",
          }
        })

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 402) {
            dispatch('auth/logOutAttemptDuplicate', payload , {root: true})
            
            payload.toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Sesion caducada",
                icon: "CoffeeIcon",
                variant: "warning",
                text: error.response.data.error,
              }
            })
          }
        } else {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error de servidor",
              icon: "CoffeeIcon",
              variant: "error",
              text: error.response.data.error,
            }
          })
        }
      })
  },
  searchDisAccounts({ commit, state, dispatch }, payload) {
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`
    axiosInstance
      .post('/api/v1/searchAccount', {
        query: payload.query,
      })
      .then(response => {
        if (response.data.data.data.length > 0) {
          let dataFormat = response.data.data.data.map(account => {
            const accFormat = {
              city: account.city,
              country: account.country,
              value: account.id_account,
              id_account_type: account.id_account_type,
              id_identification_type: account.id_identification_type,
              identification: account.identification,
              last_name: account.last_name,
              text: account.name,
              state: account.state,
            }
            return accFormat
          })
            dataFormat = dataFormat.filter(acc => acc.id_account_type !== 'Cliente' && acc.id_account_type === 'Distribuidor')
            commit('ticket/UPDATE_DIS_ACCOUNTS', dataFormat, { root: true })
            commit('ticket/UPDATE_ITS_DIS_SEARCH_ACCOUNTS', false, { root: true })
          
        } else  {
          commit('ticket/UPDATE_DIS_ACCOUNTS', [], { root: true })
          commit('ticket/UPDATE_ITS_DIS_SEARCH_ACCOUNTS', false, { root: true })
        }
      }).catch(error => {
        // Close animation if passed as payload
        dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
        payload.toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Sesion caducada",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Token de acceso expirado",
          }
        })

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 402) {
            dispatch('auth/logOutAttemptDuplicate', payload , {root: true})
            
            payload.toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Sesion caducada",
                icon: "CoffeeIcon",
                variant: "warning",
                text: error.response.data.error,
              }
            })
          }
        } else {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error de servidor",
              icon: "CoffeeIcon",
              variant: "error",
              text: error.response.data.error,
            }
          })
        }
      })
  },
  setAccounts({ commit, state, dispatch }, payload) {
    if (payload.isTicket) {
      if(payload.ally){
        commit('ticket/UPDATE_ALLY_ACCOUNTS', payload.value, { root: true })
      }else{
        commit('ticket/UPDATE_DIS_ACCOUNTS', payload.value, { root: true })
      }
      
    } else {
      commit('UPDATE_ACCOUNTS', payload.value)
    }
  },
  setItsSearchAccounts({ commit, state, dispatch }, payload) {
    if (payload.isTicket) {
      if(payload.ally){
        commit('ticket/UPDATE_ITS_AllY_SEARCH_ACCOUNTS', payload.isSearch, { root: true })
      }else{
        commit('ticket/UPDATE_ITS_DIS_SEARCH_ACCOUNTS', payload.isSearch, { root: true })
      }
    } else {
      commit('UPDATE_ITS_SEARCH_ACCOUNTS', payload.isSearch)
    }
  },

}
