import Vue from 'vue'
import Vuex from 'vuex'

import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'
import moduleAuth from './auth/moduleAuth.js'
import moduleTicket from './ticket/moduleTicket'
import moduleAccount from './account/moduleAccount.js'
import moduleContact from './contact/moduleContact'
import release from './release/moduleRelease'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  getters,
  mutations,
  state,
  actions,
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth: moduleAuth,
    contact: moduleContact,
    account: moduleAccount,
    ticket: moduleTicket,
    release,

  },
  strict: process.env.NODE_ENV !== 'production',
})