import {axiosInstance} from '../../axios.conf'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {

  getSupportTypes({ commit, state, dispatch }, payload) {

    var myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${localStorage.getItem('access_token')}`);
    myHeaders.append("Content-Type", "application/json");
    
    
    var raw = JSON.stringify({});
    
    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };
    
    fetch("https://t2c.arqbs.com/SpringBootTime2ChangeSpellty-APP/api/v1/supportType", requestOptions)
      .then(response => response.text())
      .then(result => console.log(result))
      .catch(error => console.log('error', error));




    //axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`

    axiosInstance
      .post(
        '/api/v1/supportType', 
        { 
          data: JSON.stringify({}),
          
        }
        )
      .then(response => {
        if (response.status === 200) {
          const formatTypes = []
          response.data.data.forEach(element => {
            formatTypes.push({
              value: element.id,
              text: element.descripcion,
            })
          })
          commit('UPDATE_SUPPORT_TYPES_INFO', formatTypes)
        }
      }).catch(error => {
        // Close animation if passed as payload
        dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
        payload.toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Sesion caducada",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Token de acceso expirado",
          }
        })

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 402) {
            dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
            payload.toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Sesion caducada",
                icon: "CoffeeIcon",
                variant: "warning",
                text: error.response.data.error,
              }
            })
          }
        } else {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error de servidor",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.error,
            }
          })
        }
      })
  },
  getTicketTypes({ commit, state, dispatch }, payload) {
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`

    axiosInstance
      .post('/api/v1/ticketType', { data: JSON.stringify({}) })
      .then(response => {
        if (response.status === 200) {
          const formatTypes = []
          response.data.data.forEach(element => {
            formatTypes.push({
              value: element.id,
              text: element.descripcion,
            })
          })
          commit('UPDATE_TICKET_TYPES_INFO', formatTypes)
        }
      }).catch(error => {
        // Close animation if passed as payload
        dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
        payload.toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Sesion caducada",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Token de acceso expirado",
          }
        })

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 402) {
            dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
            
            payload.toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Sesion caducada",
                icon: "CoffeeIcon",
                variant: "warning",
                text: error.response.data.error,
              }
            })
          }
        } else {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error de servidor",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.error,
            }
          })
        }
      })
  },
  createTicket({ commit, state, dispatch }, payload) {
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`

    axiosInstance
      .post('/api/v1/ticket', {
        ...payload.ticketDetails,
      })
      .then(response => {
        if (response.status === 200 && response.data.data.ticket_id) {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Ticket Creado",
              icon: "CoffeeIcon",
              variant: "success",
              text: `Se ha creado el Ticket #${response.data.data.ticket_id}`,
            }
          })
        } else {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error al crear Ticket",
              icon: "CoffeeIcon",
              variant: "danger",
              text: "Ha ocurrido un error al generar el Ticket",
            }
          }) 
        }
      }).catch(error => {
        // Close animation if passed as payload
        dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
        payload.toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Sesion caducada",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Token de acceso expirado",
          }
        })

        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 402) {
            dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
            
            payload.toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Sesion caducada",
                icon: "CoffeeIcon",
                variant: "warning",
                text: error.response.data.error,
              }
            })
          }
        } else {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error de servidor",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.error,
            }
          })
        }
      })
  },
  getTickets({ commit, state, dispatch }, payload) {
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`

    axiosInstance
      .post(`/api/v1/ticket/list?page=${payload.page}`, { mobile_number: payload.mobile_number })
      .then(response => {
        if (response.status === 200) {
          console.log("status===200");
          const tickets = response.data.data.data
          if(tickets.length > 0){
            if(payload.page==1){
              commit('UPDATE_TICKETS', tickets)
              commit('UPDATE_TICKETS_PAGES', payload.page+1)
              commit('UPDATE_MORE_TICKETS', true)
            }else{
              commit('UPDATE_ADD_TICKETS', tickets)
              commit('UPDATE_TICKETS_PAGES', payload.page+1)
            }
          }
          else{
            commit('UPDATE_MORE_TICKETS', false)
          }
          payload.animation()
        }
      }).catch(error => {
        // Close animation if passed as payload
        console.log("error", error);

        dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
        payload.toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Sesion caducada",
            icon: "CoffeeIcon",
            variant: "warning",
            text: "Token de acceso expirado",
          }
        })
        
        if (error.response) {
          if (error.response.status === 401 || error.response.status === 403 || error.response.status === 402) {
            dispatch('auth/logOutAttemptDuplicate', payload, {root: true})
            payload.toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Sesion caducada",
                icon: "CoffeeIcon",
                variant: "warning",
                text: error.response.data.error,
              }
            })
          }
        } else {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error de servidor",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.error,
            }
          })
        }
      })
  },

}
