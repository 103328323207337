/*= ========================================================================================
  File Name: moduleChat.js
  Description: Chat Module
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: fjose
========================================================================================== */

import state from './moduleTicketState.js'
import mutations from './moduleTicketMutations.js'
import actions from './moduleTicketActions.js'
import getters from './moduleTicketGetters.js'

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
