/*= ========================================================================================
  File Name: actions.js
  Description: Vuex Store - actions
  ----------------------------------------------------------------------------------------
  Author: fjose
========================================================================================== */

import { axiosInstance } from '../axios.conf'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

const actions = {
  // ////////////////////////////////////////////
  // SIDEBAR & UI UX
  // ////////////////////////////////////////////

  updateSidebarWidth({ commit }, width) {
    commit('UPDATE_SIDEBAR_WIDTH', width)
  },
  toggleContentOverlay({ commit }) {
    commit('TOGGLE_CONTENT_OVERLAY')
  },
  updateTheme({ commit }, val) {
    commit('UPDATE_THEME', val)
  },
  updateWindowWidth({ commit }, width) {
    commit('UPDATE_WINDOW_WIDTH', width)
  },

  timeToChangeOverlay({commit}, active){
    commit('TIME_OVERLAY', active)
  },
  timeToChage({commit}, active){
    commit('TIME_TO_CHANGE_STATUS', active)
  },
  // ////////////////////////////////////////////
  // COMPONENT
  // ////////////////////////////////////////////

  // VxAutoSuggest
  updateStarredPage({ commit }, payload) {
    commit('UPDATE_STARRED_PAGE', payload)
  },

  //  The Navbar
  arrangeStarredPagesLimited({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_LIMITED', list)
  },
  arrangeStarredPagesMore({ commit }, list) {
    commit('ARRANGE_STARRED_PAGES_MORE', list)
  },

  isEditForm({ commit, state, dispatch }, isEdit) {
    commit('SHOW_EDIT_FORM', isEdit)
  },
  // Decide which component to display; Lead information, contact information or Tabs to add information
  driverComponentLeadContact({
    commit, state, dispatch, getters,
  }, payload) {
    // const formatNumbers = getters.getMultiplesPhoneNumbers(payload)

    // payload.formatNumbers = formatNumbers

    dispatch('whatsAppContactInfoFromServer', payload)

    /**/
  },
  showUpdateLocalStorange({ commit, state, dispatch }, show) {
    commit('SHOW_MESSAGE_UPDATE_LOCAL_STORANGE', show)
  },

  // Get info of WhatsApp contact through a HTTP request
  whatsAppContactInfoFromServer({
    commit, state, dispatch, getters,
  }, payload) {
    axiosInstance.defaults.headers.get['Content-Type'] = 'application/x-www-form-urlencoded'
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('access_token')}`

    axiosInstance
      .get(`/api/v1/contact/${payload.mobileOpt1}`, { data: JSON.stringify({}) })
      .then(response => {
        dispatch('timeToChangeOverlay', false)
        if (response.status === 200) {
          const responseData = response.data
          if (responseData.data) {
            commit('contact/UPDATE_CONTACT_INFO', responseData.data)
            commit('SHOW_CONTACT_INFO')
          } else {
            const data = {
              id: '',
              firstname: '',
              lastname: '',
              identification_type_id: 1,
              identification: '',
              country: 'COL', // String:Required
              state: '05', // String:Required S
              city: '05001', // String:Required
              account_id: '', // String:Optional
              mobile_number: payload.mobileOpt1, // String:Required
              email: '', // String:Required
              account: {
                city: '05001',
                country: 'COL',
                id_account: '',
                id_account_type: 'Cliente',
                identification: null,
                last_name: '',
                name: '',
                state: '05',
              },
            }
            commit('contact/UPDATE_CONTACT_INFO', data)
            commit('SHOW_FORM_ADD_DATA') 
          }
        }
      }).catch(error => {
        // Close animation if passed as payload
        if (error.response) {
          if (error.response.status == 401 || error.response.status == 403 || error.response.status == 402) {
            dispatch('auth/logOutAttemptDuplicate', payload)
            payload.toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Sesion caducada",
                icon: "CoffeeIcon",
                variant: "warning",
                text: error.response.data.error,
              }
            })
          }
        } else {
          payload.toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: "Error de servidor",
              icon: "CoffeeIcon",
              variant: "danger",
              text: error.response.data.error,
            }
          })
        
        }
      })
  },
  saveInlocalStorange({ commit, state, dispatch }, payload) {
    try {
      localStorage.setItem(
        payload.key_1 + payload.key_2,
        JSON.stringify(payload.data),
      )
    } catch (error) {
      /* ---When has not more space, remove two items from local storage--*/

      const localStorageItems = [] // Array to hold the keys

      // Iterate over localStorage and insert the keys that meet the condition into localStorageItems
      for (var i = 0; i < localStorage.length; i++) {
        if (
          localStorage.key(i).substring(0, payload.key_1.length)
          == payload.key_1
        ) {
          localStorageItems.push(localStorage.key(i))
        }
      }

      // Iterate over localStorageItems and remove the items by key
      const quantityToRemove = 2
      for (var i = 0; i < quantityToRemove; i++) {
        localStorage.removeItem(localStorageItems[i])
      }
    }
  },
  removeAllItemsLocalStorange({ commit, state, dispatch }) {
    const localStorageItems = [] // Array to hold the keys
    const localStorageActivities = []

    // Iterate over localStorage and insert the keys that meet the condition into localStorageItems
    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 20) == 'whatsAppContactInfo_') {
        localStorageItems.push(localStorage.key(i))
      }
    }

    // Iterate over localStorageItems and remove the items by key
    for (let i = 0; i < localStorageItems.length; i++) {
      localStorage.removeItem(localStorageItems[i])
    }

    for (let i = 0; i < localStorage.length; i++) {
      if (localStorage.key(i).substring(0, 11) == 'Activities_') {
        localStorageActivities.push(localStorage.key(i))
      }
    }

    for (let i = 0; i < localStorageActivities.length; i++) {
      localStorage.removeItem(localStorageActivities[i])
    }
  },

  reloadInfo({
    commit, state, dispatch, getters,
  }, payload) {
    const formatNumbers = getters.getMultiplesPhoneNumbers(payload)
    payload.formatNumbers = formatNumbers
    payload.isRefreshAction = true
    dispatch('whatsAppContactInfoFromServer', payload)
  },
  actuTemp({ commit, state, dispatch }, payload) {
    commit('UPDATE_TEMP', payload)
  },
}

export default actions
